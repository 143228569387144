export function reCaptchaInit(context) {
    if (typeof grecaptcha === 'undefined') {
        return;
    }

    context = typeof context !== 'undefined' ? context : document;

    let captchaWraps = context.querySelectorAll('.custom-recaptcha');

    if (captchaWraps.length) {
        captchaWraps.forEach(captchaWrap => {
            let sitekey = captchaWrap.dataset.sitekey;

            if (sitekey) {
                let grecaptchaId = grecaptcha.render(captchaWrap, {
                    sitekey: sitekey
                });

                captchaWrap.dataset.grecaptchaId = grecaptchaId;
            }
        });
    }
}

window.reCaptchaInit = reCaptchaInit;

export function reCaptchaReset(context) {
    if (typeof grecaptcha === 'undefined') {
        return;
    }

    context = typeof context !== 'undefined' ? context : document;

    let captchaWraps = context.querySelectorAll('.custom-recaptcha');

    if (captchaWraps.length) {
        captchaWraps.forEach(captchaWrap => {
            let grecaptchaId = captchaWrap.dataset.grecaptchaId;

            if (typeof grecaptchaId !== 'undefined') {
                grecaptcha.reset(grecaptchaId);
            }
        });
    }
}
