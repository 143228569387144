window.addEventListener('DOMContentLoaded',() => {
    initMenu('[data-menu-mobile]','[data-burger-mobile]', '[data-sub-menu-mobile]')
    initMenu('[data-menu]','[data-burger]', '[data-sub-menu]')
})

function initMenu(parentElem, burgerElem, menuElem, anchorElem = false) {
    const parent = document.querySelector(parentElem)
    const burger = document.querySelector(burgerElem)
    const menu = document.querySelector(menuElem)

    if (burger && menu) {
        burger.addEventListener('click', () => {
            if (burger.classList.contains('_active')) {
                remove()
            } else {
                add()
            }
        })
        window.addEventListener('resize', resizeControl)

        if(anchorElem) {
            [...menu.querySelectorAll(`.${anchorElem}`)]
                .forEach(el => {
                    el.addEventListener('click', remove)
                })
        }

    } else {
        throw new Error('Menu is not init')
    }

    function resizeControl() {
        const w = document.documentElement.clientWidth;
        if (w > 1279) {
            remove()
        }
    }

    function remove() {
        parent.classList.remove('_active')
        burger.classList.remove('_active')
        menu.classList.remove('_active')
    }

    function add() {
        parent.classList.add('_active')
        burger.classList.add('_active')
        menu.classList.add('_active')
    }
}
