export function createItemNews(item) {
    return `
        <div class="tab__item item-news">
            <a href="${item.url}" class="item-news__image">
                <img src="${item.img}" width="350" height="200" alt="">
            </a>

            <div class="item-news__name">${item.name}</div>
            <div class="item-news__text">${item.text}</div>

            <div class="item-news__info">
                <a href="${item.url}" class="item-news__more">${item.link}</a>
                <span class="item-news__date">${item.date}</span>
            </div>
        </div>
    `
}
