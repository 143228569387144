import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import inputMaskInit from '../../components/forms/mask';
import {reCaptchaInit} from '../../components/forms/recaptcha';

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();

    let modal = new Modal(this, {
        onAfterStart() {
            const href = modal.element.dataset.href;

            if (href) {
                let frame = document.createElement('iframe');
                frame.src = href;
                modal.content.append(frame)
            }

            inputMaskInit(modal.content);
            reCaptchaInit(modal.content);
        },
    });

    return false;
});

window.addEventListener('DOMContentLoaded', () => {
    inputMaskInit();
});
