import { createItem, getApartmentBody } from "./_item"
import { getHouseId } from "./_canvas"

window.addEventListener('DOMContentLoaded', () => {
    // Вкладка квартир
    const apartmentsTab = document.querySelector('[data-sale-body="apartments"]')
    if(apartmentsTab) range(apartmentsTab); checkbox(apartmentsTab); sendByLink(apartmentsTab)
    // Вкладка офисов
    const officeTab = document.querySelector('[data-sale-body="office"]')
    if(officeTab) range(officeTab); checkbox(officeTab)
})
function sendByLink(parent) {
    if(parent) {
        const container = parent.querySelector('[data-container]')
        const moreLink = parent.querySelector('[data-load-more]')

        moreLink.addEventListener('click', (e) => {
            e.preventDefault()
            sendData(container, moreLink.href, false, true)
        })
    }
}

export function checkbox(parent) {
    if(parent) {
        const rooms = parent.querySelectorAll('[data-id]')

        if(rooms.length > 0) {
            rooms.forEach(element => {
                // Обновление данных формы после нажатия на чекбоксы
                element.addEventListener('click', () => {
                    setTimeout(() => {
                        afterInput(parent, true)
                    }, 500);
                })
            })
        }
    }
}

function range(parent) {
    const range = parent.querySelectorAll('[data-range]')
    const rangeBefore = parent.querySelectorAll('[data-before]')
    const rangeAfter = parent.querySelectorAll('[data-after]')

    if(range.length > 0) range.forEach((el, key) => setRange(el, rangeBefore[key], rangeAfter[key]))

    function setRange(range, before, after) {
        const step = range.getAttribute('step')
        const minValue = range.getAttribute('min')
        const maxValue = range.getAttribute('max')
        // Начальные видимые значения
        setValue(before, minValue)
        setValue(after, maxValue)
        // присваиваем значение в атрибут, потому что value нельзя нормально записать
        range.dataset.range = maxValue

        // Изменение значения при "вводе"
        range.addEventListener('input', () => {
            if(parseFloat(range.value) + parseFloat(step) > parseFloat(maxValue)) {
                setValue(after, maxValue)
                range.dataset.range = maxValue
                return
            }
            setValue(after, range.value)
            range.dataset.range = range.value
        })
        // Обновление данных формы после нажатия на ползунок
        range.addEventListener('mouseup', () => {
            setTimeout(() => {
                afterInput(parent, true)
            }, 500);
        })
    }
    // Устанавливает значение числа разделенного точками
    function setValue(elem, value) {
        elem.querySelector('span').textContent = parseFloat(value).toLocaleString('de')
    }
}
// Вызывается через 500ms после ввода
export function afterInput(parent, input) {
    // форма внутри вкладки
    const form = parent.getElementsByTagName('form')[0]

    if(form && form.dataset.filter != 'plan') {
        const inputs = form.getElementsByTagName('input')
        const container = parent.querySelector('[data-container]')
        parent.querySelector('[data-load-more]').dataset.loadMore = 0

        if(input || container.dataset.container != 'loaded') {
            let result = [];
            // Перебираем коллекцию значений и возвращаем массив с "частями" будущей ссылки
            [...inputs].forEach(el => {
                if(el.type === 'checkbox') {
                    if(el.checked) {
                        result.push(`${el.dataset.name}[]=${el.dataset.id}`)
                    }
                } else if(el.type === 'radio')  {
                    if(el.checked) {
                        result.push(`${el.name}=${el.dataset.id}`)
                    }
                } else if(el.type === 'range') {
                    result.push(`${el.name}=${el.dataset.range}`)
                } else {
                    result.push(`${el.name}=${el.value}`)
                }
            });
            // отправляем данные формы
            if(form.action != window.location.href) {
                sendData(container, `${form.action}?${result.join('&')}${getHouseId('&')}`)
                container.dataset.container = 'loaded'
            }
        }
    }
}
// функция отправки данных формы
function sendData(container, url, replace, isLinkClicked) {
    const xhr = new XMLHttpRequest()
    const moreLink = container.nextElementSibling.querySelector('[data-load-more]')
    const bodyBox = document.querySelector('[data-body-box]');
    bodyBox.classList.add('process-load');
    xhr.open('GET', url)
    xhr.send()
    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 400) {
            const response = JSON.parse(xhr.responseText)
            if(response.results.length > 0) {
                // Ссылка для "загрузить еще"
                updateLink(moreLink, url, response.results.length)
                update(response, container, replace)
            } else {
                if(!isLinkClicked) {
                    container.textContent = ''
                    container.insertAdjacentHTML('beforeend', '<p>По заданным параметрам ничего не найдено</p>')
                }
                moreLink.classList.remove('show')
            }
            setTimeout(() => {
                bodyBox.classList.remove('process-load');
            }, 500);
            return;
        } else {
            setTimeout(() => {
                bodyBox.classList.remove('process-load');
            }, 500);
            console.log('Xhr error');
        }

    }
}

function updateLink(moreLink, url, step) {
    let current = moreLink.dataset.loadMore ? moreLink.dataset.loadMore : 0
    current = +current + +step
    moreLink.dataset.loadMore = current

    if(step < 4) {
        moreLink.classList.remove('show')
        return
    }
    moreLink.classList.add('show')

    if(url.indexOf('&step', 0) != -1) {
        moreLink.href = url.slice(0, url.indexOf('&step', 0))
    } else {
        moreLink.href = url
    }
    moreLink.href = `${moreLink.href}&step=${moreLink.dataset.loadMore}`
}
function update(data, container, replace) {
    let result = [];

    if(data.results.length > 0) {

        for (let index = 0; index < data.results.length; index++) {
            result.push(createItem(data.results[index][0]))
        }
        // Обновляем квартиры в выдаче
        updateItems(result, container, replace)
        //
        getApartmentBody()
    }
}

export function updateItems(arr, container, replace = true) {
    if(replace) {
        container.textContent = ''
    }

    for (let index = 0; index < arr.length; index++) {
        container.insertAdjacentHTML('beforeend', arr[index])
    }
}
