import 'lazysizes';
require('../scss/app.scss');
window.noZensmooth = true;

require('./default/_menu')
require('./default/_swiper')
require('./_script')
require('./default/_read-more')
require('./default/_toggle-content')
require('./default/_maps')
require('./default/_video')
require('./default/objectFilter/_range')
require('./default/objectFilter/_canvas')
require('./default/_panorama')
// TODO Убрать лишнее
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
// Зависимости
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
