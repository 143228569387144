import Inputmask from 'inputmask';

export default function inputMaskInit(context) {
    context = typeof context !== 'undefined' ? context : document;

    let phoneInputs = context.querySelectorAll('[data-phone-mask]');

    if (phoneInputs.length) {
        Inputmask({
            mask: '+7 (999) 999-99-99',
            showMaskOnHover: false,
            clearIncomplete: true,
        }).mask(phoneInputs);
    }

    let nameInputs = context.querySelectorAll('[data-name-mask]');

    if (nameInputs.length) {
        Inputmask({
            showMaskOnHover: false,
            regex: '[а-яА-Я \-]*',
            onKeyValidation: function (key, result) {
                let errorWrap = findErrorWrap(this);
                if (!errorWrap) {
                    return;
                }

                if (result === false) {
                    errorWrap.innerHTML = '<li>Введите имя русскими буквами</li>';
                    errorWrap.style.display = 'block';
                } else {
                    errorWrap.innerHTML = '';
                    errorWrap.style.display = 'none';
                }
            },
        }).mask(nameInputs);
    }

    const findErrorWrap = (element) => {
        const errors = Array.from(element.parentNode.children);
        return errors.find(el => el.classList.contains('errors') && el !== element) || null;
    };
}
