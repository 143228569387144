// ВАЖНО: ленивая загрузка в слайдере работает только при включенном кеше, иначе изображения в области видимости загружаются дважды

// js для свайпера, подключается блоками
// подробнее тут <https://swiperjs.com/get-started>
import SwiperCore, {Swiper, Pagination, Navigation, Lazy} from "swiper/core";
SwiperCore.use([Swiper, Pagination, Navigation, Lazy]);

// стили для свайпера
// брать тут <https://swiperjs.com/swiper-api#styles>, только для нужных компонентов
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/navigation/navigation.min.css";

window.addEventListener("DOMContentLoaded", () => {
    // ########################################################
    if (document.querySelector(".swiper-container-main-slider")) {
        new Swiper(".swiper-container-main-slider", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,

            // // ========================================================
            navigation: {
                prevEl: '.slider-main__button-prev',
                nextEl: '.slider-main__button-next',
            },
            // // ========================================================
            pagination: {
                type: 'bullets',
                el: '.swiper-pagination-main-slider'
            },
            // // ========================================================
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: false,
            },
            // Включить предзагрузку изображений
            preloadImages: false,
            // Слежка за видимыми слайдами
            watchSlidesProgress: false,
            // Добавление класса видимым сладам
            watchSlidesVisibility: false,
        });
    }
    // ########################################################
    if (document.querySelector(".swiper-container-content")) {
        let contentSlider;
        let point = window.matchMedia('(min-width:768px) and (max-width: 800px)');

        contentSlider = new Swiper(".swiper-container-content", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            // slidesPerColumnFill: 'row',

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                768: {
                    noSwiping: true,
                    allowTouchMove: false,
                },
            },

            // // ========================================================
            pagination: {
                type: 'bullets',
                el: '.swiper-pagination-content'
            },
            // // ========================================================
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: false,
            },
            // Включить предзагрузку изображений
            preloadImages: false,
            // Слежка за видимыми слайдами
            watchSlidesProgress: false,
            // Добавление класса видимым сладам
            watchSlidesVisibility: false,
        });

        window.addEventListener('resize', () => {
            if(point.matches) {
                contentSlider.slideTo(0);
            }
        })
    }
    // ########################################################
    if (document.querySelector("[data-swiper-map]")) {
        new Swiper("[data-swiper-map]", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,
            // Выключить навигацию при переполнении
            watchOverflow: true,
            // Расстояние между слайдами
            spaceBetween: 20,
            //
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                1280: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                },
            },
            // // ========================================================
            navigation: {
                nextEl: '[data-swiper-map-next]',
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Слежка за видимыми слайдами
            watchSlidesProgress: false,
            // Добавление класса видимым сладам
            watchSlidesVisibility: false,
        });
    }
    // ########################################################
    const sliders = document.querySelectorAll("[data-swiper-news]") || false
    if(sliders) {
        sliders.forEach((slider, key) => {
            new Swiper(slider, {
                // Вкл\откл свайпов на пк
                simulateTouch: true,
                // Курсор перетаскивания
                grabCursor: true,
                // отключает слайдер, если все слайды видны
                watchOverflow: true,
                // расстояние между слайдами
                spaceBetween: 20,
                // // тип заполнения слайдов, если выводится несколько колонок/строк
                // slidesPerColumnFill: 'row',
                // // ========================================================
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                },
                // // ========================================================
                navigation: {
                    prevEl: '[data-swiper-prev]',
                    nextEl: '[data-swiper-next]',
                },
                // // ========================================================
                pagination: {
                    type: 'custom',
                    el: slider.parentElement.querySelector('[data-swiper-pagination]'),
                    clickable: true,
                    // не больше 99 слайдов
                    renderCustom: function (swiper, current, total) {
                        return ('0' + current).slice(-2) + ' ... ' + ('0' + total).slice(-2);
                    }
                },
                // // ========================================================
                // Ленивая загрузка
                lazy: {
                    // Начало загрузки при свайпе
                    loadOnTransitionStart: true,
                    // Подгружать prev/next картинки
                    loadPrevNext: false,
                },
                // Включить предзагрузку изображений
                preloadImages: false,
                // Слежка за видимыми слайдами
                watchSlidesProgress: false,
                // Добавление класса видимым сладам
                watchSlidesVisibility: false,
            });
        });
    }
});

export function initSimpleSlider(element) {
    const slider = element.querySelector('.swiper-container-simple');
    if (!slider) {
        return;
    }

    new Swiper(slider, {
        // Вкл\откл свайпов на пк
        simulateTouch: true,
        // Курсор перетаскивания
        grabCursor: true,

        watchOverflow: true,
        spaceBetween: 20,

        // Мобайл-first подход (min-width: ...)
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            },
            1280: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
        },
        // // ========================================================
        navigation: {
            prevEl: slider.parentElement.querySelector('.slider-simple__button-prev'),
            nextEl: slider.parentElement.querySelector('.slider-simple__button-next'),
        },
        // // ========================================================
        pagination: {
            type: 'bullets',
            el: slider.parentElement.querySelector('.swiper-pagination-simple'),
            clickable: true,
        },
        // // ========================================================
        // Ленивая загрузка
        lazy: {
            // Начало загрузки при свайпе
            loadOnTransitionStart: true,
            // Подгружать prev/next картинки
            loadPrevNext: false,
        },
        // Включить предзагрузку изображений
        preloadImages: false,
        // Слежка за видимыми слайдами
        watchSlidesProgress: false,
        // Добавление класса видимым сладам
        watchSlidesVisibility: false,
    });
}

export function updateSimpleSlider(element) {
    const slider = element.querySelector('.swiper-container-simple');
    if (!slider) {
        return;
    }

    if (!slider.classList.contains('swiper-container-initialized')) {
        initSimpleSlider(element);
    } else {
        slider.swiper.update();
    }
}