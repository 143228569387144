
window.addEventListener('DOMContentLoaded', () => {
    const block = document.getElementById('panorama');
    if(block) init(block)
})

function init(block) {
    const triggers = block.querySelectorAll('[data-trigger]')
    block.addEventListener('click', loadIframe)

    function loadIframe(event) {
        event.preventDefault()
        if([...triggers].includes(event.target)) {
            block.textContent = ''
            block.insertAdjacentHTML('beforeend', createIframe(event.target.href))
            block.removeEventListener('click', loadIframe)
        }
    }

    function createIframe(url) {
        console.log(url);
        return `<iframe src="${url}" name="tour-avitec" scrolling="no" frameborder="0" allowfullscreen="true"></iframe>`
    }
}


export function get(method, action) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open(method, action)
        xhr.send()
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 400) {
                resolve(xhr)
            } else {
                console.log('Xhr error');
            }
        }
    })
}
