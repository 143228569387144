window.addEventListener('DOMContentLoaded',() => start('[data-read-btn]', '[data-read-text]'))

function start(btnSelectors, textSelectors) {
    const btns = document.querySelectorAll(btnSelectors)
    const texts = document.querySelectorAll(textSelectors)

    if(btns.length > 0 && btns.length == texts.length) {
        btns.forEach((btn, key) => readMore(btn, texts[key]));
    }
}

function readMore(btn, text) {
    const textLength = text.dataset.readText
    let textContent = text.textContent

    if(textContent.length > textLength) {
        const firstText = textContent.slice(0, textLength)
        const secondText = textContent.slice(textLength)
        const btnText = btn.textContent

        text.textContent = firstText
        addDots(text)

        btn.addEventListener('click', click)

        function click() {
            let status = btn.dataset.readBtn

            if(status === 'hide') {
                smallText()
            } else if(status === 'show') {
                text.textContent = firstText
                addDots(text)
                btn.dataset.readBtn = 'hide'
                btn.textContent = btnText
            } else {
                smallText()
                btn.remove()
                removeEventListener('click', click)
            }
        }

        function smallText() {
            removeDots(text)
            text.textContent = firstText + secondText
            btn.dataset.readBtn = 'show'
            btn.textContent = 'Свернуть'
        }
    }


    function addDots(elem) {
        elem.textContent += '...'
    }
    function removeDots(elem) {
        elem.textContent = elem.textContent.slice(0, -3)
    }
}

