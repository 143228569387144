import { currentRoom } from "./_current-room";
import { getFloors, renderPlanForm } from "./_planForm";
import { get } from "../_panorama";
import { createStatusElement } from "./_statusElement";

window.addEventListener('DOMContentLoaded', () => {
    if(typeof objects !== 'undefined') {
        const canvas = new Canvas('plan', 'plan-canvas', 'plan-canvas-text', objects);
    }
})
class Canvas {
    constructor(parent, canvas, textCanvas, objects) {
        this.plan = document.getElementById(parent) || false

        if(this.plan) {
            this.canvas = document.getElementById(canvas)
            this.canvasContext = this.canvas.getContext('2d')
            this.textCanvas = document.getElementById(textCanvas)
            this.textCanvasContext = this.textCanvas.getContext('2d')
            this.container = this.canvas.closest('[data-container]')
            this.objects = this.checkObjectDefined(objects)
            this.bindMousemovePlan = this.mousemovePlan.bind(this)
            this.bindClickPlan = this.clickPlan.bind(this)
            this.bindClickFloor = null
            this.bindMousemoveFloor = null
            this.squareArr = []
            this.tab = this.container.closest('[data-sale-body="plan"]')
            this.clear = false
            this.init()
        }
    }

    init() {
        this.paintCanvas(this.objects)
        // Добавляем слушателей
        this.canvas.addEventListener('mousemove', this.bindMousemovePlan)
        this.canvas.addEventListener('click', this.bindClickPlan);

        window.onpopstate = this.remove
    }

    remove() {
        if(history.state == null) {
            window.location.reload()
        }
    }

    mousemovePlan(event) {

        for(let i = this.squareArr.length - 1; i >= 0; i--) {

            if (this.canvasContext.isPointInPath(this.squareArr[i], this.scaleFactor(event).offsetX, this.scaleFactor(event).offsetY)) {
                this.canvasContext.fillStyle = this.objects[i].fill;
                this.canvasContext.fill(this.squareArr[i]);
                return
            } else {
                this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
            }
        }
    }

    clickPlan(event) {
        for(let i = this.squareArr.length - 1; i >= 0; i--) {

            if (this.canvasContext.isPointInPath(this.squareArr[i], this.scaleFactor(event).offsetX, this.scaleFactor(event).offsetY)) {

                get('GET', '/sale/form'+ getHouseId('?') + getEntrance(this.objects[i], '&'))
                .then((data) => {
                    const response = JSON.parse(data.responseText)

                    if(response) {
                        // Генерируем и вставляем форму
                        this.container.parentNode.insertAdjacentHTML('afterbegin', renderPlanForm(response, this.objects))
                        // выбираем подьезд в форме на который нажали на холсте ...
                        document.getElementById(getEntrance(objects[i], '', '')).checked = true
                        // ... и нажимаем на него чтобы сгенерировать ссылку
                        document.getElementById(getEntrance(objects[i], '', '')).click()
                        // Добавляем слушателей для подьездов
                        this.planEntrancelistener(this.tab)
                        // Добавляем слушателей для этажей текущего подьезда
                        this.planFloorListener(this.tab, response)
                        // Изменяем размер холста (для старых изображений он такой, также координаты нужно делить на 2)
                        this.canvas.width = 895
                        this.canvas.height = 660
                        // Обновляем холст
                        this.clearPlan(response)
                        // Рендерим первый доступный этаж
                        this.firstUpdatePlan(response)
                    } else {
                        console.log('Xhr error');
                    }
                })
                .then(() => {
                    if(window.location.hash != '#plan') {
                        history.pushState(null,'', '#plan')
                    }
                })
                return
            }

        }
    }
    firstUpdatePlan(response) {
        // Только для первого достпуного этажа
        selectFloorById(response.floors[getFirstFloorIndex(response.floors)])
        // Заменяем картинку на картинку нужного этажа
        this.replaceFloorImage(response.floors[getFirstFloorIndex(response.floors)])
        // Ищем координаты по Id этажа
        // Создаем закрашенные области
        this.paintCanvas(this.canvasCoordsGet(response.floors[getFirstFloorIndex(response.floors)].floor_id, response.apartments), true, 2)
        // Привязываем контекст и передаем аргументы
        this.bindClickFloor = this.clickFloor.bind(this, response.apartments, response.floors[getFirstFloorIndex(response.floors)].floor_id)
        this.bindMousemoveFloor = this.mousemoveFloor.bind(this, response.apartments, response.floors[getFirstFloorIndex(response.floors)].floor_id)
        // Добавляем новых слушателей для квартир на этаже
        this.canvas.addEventListener('click', this.bindClickFloor);
        this.canvas.addEventListener('mousemove', this.bindMousemoveFloor);
    }

    clearPlan() {
        if(!this.clear) {
            // Удаляем холст с текстом
            this.textCanvas.remove()
            // это тоже можно удалить
            this.container.parentNode.querySelector('[data-text]').remove()
            // убираем слушателей
            this.canvas.removeEventListener('mousemove', this.bindMousemovePlan);
            this.canvas.removeEventListener('click', this.bindClickPlan);

            this.clear = true
        }
        // Очищаем холст
        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.width);
        // обнуляем массив
        this.squareArr = []
    }

    clickFloor(apartments, id, event) {
        for(let i = this.squareArr.length - 1; i >= 0; i--) {
            if (this.canvasContext.isPointInPath(this.squareArr[i], this.scaleFactor(event).offsetX, this.scaleFactor(event).offsetY)) {
                this.getApartment(`apartment_id=${apartments[id][i].apartament_id}`)
                return
            }
        }
    }
    mousemoveFloor(apartments, id, event) {
        for(let i = this.squareArr.length - 1; i >= 0; i--) {
            if (this.canvasContext.isPointInPath(this.squareArr[i], this.scaleFactor(event).offsetX, this.scaleFactor(event).offsetY)) {
                this.canvasContext.fillStyle = '#F40000'
                this.canvasContext.fill(this.squareArr[i])

                document.querySelector(`.popup-${apartments[id][i].apartament_id}`).classList.remove('hide')
            } else {
                document.querySelector(`.popup-${apartments[id][i].apartament_id}`).classList.add('hide')

                if(typeof apartments[id][i].fill !== 'undefined') {
                    this.canvasContext.fillStyle = apartments[id][i].fill
                    this.canvasContext.fill(this.squareArr[i])
                }
            }
        }
    }

    getApartment(action) {
        get('GET', '/sale/current_room'+ '?' + action + getHouseId('&'))
        .then((data) => {
            const response = JSON.parse(data.responseText)

            if(response.results) {
                // Очищаем контейнер для нового содержимого
                this.container.textContent = ''
                // Удаляем форму, она нам больше не нужна
                this.container.parentNode.querySelector('form').remove()
                // Вставляем новый контент
                this.container.insertAdjacentHTML('beforeend', currentRoom(response.results))
            } else {
                console.error('Xhr error', response);
            }
        })
    }

    planEntrancelistener(parent) {
        const entrances = parent.querySelectorAll('input[name="entrance"]')
        const floors = parent.querySelector('[data-floor]')

        if(entrances.length > 0) {
            entrances.forEach((el, key) => {
                // Обновление данных формы после нажатия на подьезды
                el.addEventListener('click', (event) => {
                    get('GET', '/sale/form'+ getHouseId('?') + getEntrance(this.objects[key], '&'))
                    .then((data) => {
                        const response = JSON.parse(data.responseText)

                        if(response) {
                            // Убираем слушателей для старых этажей
                            this.canvas.removeEventListener('click', this.bindClickFloor);
                            this.canvas.removeEventListener('mousemove', this.bindMousemoveFloor);
                            this.bindClickFloor = null
                            this.bindMousemoveFloor = null
                            // Ре-рендерим этажи
                            floors.textContent = ''
                            floors.insertAdjacentHTML('afterbegin', getFloors(response))
                            // Очищаем холст
                            this.clearPlan()
                            // Рендерим первый доступный этаж
                            this.firstUpdatePlan(response)
                            // Добавляем слушателей для новых этажей
                            this.planFloorListener(this.tab, response)
                        }
                    })
                })
            })
        }
    }

    planFloorListener(parent, response) {
        const floors = parent.querySelectorAll('input[name="floor"]')

        if(floors.length > 0) {
            floors.forEach((el, key) => {
                // Обновление данных формы после нажатия на чекбоксы
                el.addEventListener('click', (event) => {
                    if(response.floors[key].floor_id == event.target.dataset.id) {
                        this.replaceFloorImage(response.floors[key])
                        // Очищаем холст
                        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.width);
                        // обнуляем массив
                        this.squareArr = []
                        // Рисуем новые области
                        this.paintCanvas(this.canvasCoordsGet(response.floors[key].floor_id, response.apartments), true, 2)
                        // Обновляем слушателей для этажей
                        this.canvas.removeEventListener('click', this.bindClickFloor);
                        this.canvas.removeEventListener('mousemove', this.bindMousemoveFloor);
                        this.bindClickFloor = null
                        this.bindMousemoveFloor = null
                        // Привязываем контекст и передаем аргументы
                        this.bindClickFloor = this.clickFloor.bind(this, response.apartments, response.floors[key].floor_id)
                        this.bindMousemoveFloor = this.mousemoveFloor.bind(this, response.apartments, response.floors[key].floor_id)
                        // Добавляем новых слушателей для квартир на этаже
                        this.canvas.addEventListener('click', this.bindClickFloor);
                        this.canvas.addEventListener('mousemove', this.bindMousemoveFloor);
                    }
                })
            })
        }
    }

    // для нормального скейлинга изображения
    scaleFactor(event) {
        let scaleFactor = this.canvas.width / this.plan.offsetWidth;

        return event = {
            offsetX:event.offsetX * scaleFactor,
            offsetY:event.offsetY * scaleFactor
        }
    }

    paintCanvas(objects, isFill, multiplier) {
        const popupsBox = document.querySelector('#popups')
        popupsBox.textContent = ''
        // Перебираем обьект с данными (передается в шаблоне)
        this.checkObjectDefined(objects).forEach(obj => {
            this.paintSquare(obj, isFill, multiplier)
            if(obj.text) {
                this.paintText(obj)
            }
            if(obj.status) {
                this.appendStatus(obj, multiplier, popupsBox)
            }
        });
    }

    paintSquare(obj, isFill = false, multiplier = 1) {
        if(typeof obj.path === 'string') {
            obj.path = JSON.parse(obj.path)
        }

        let square = ''

        // Создаем путь как у свг, чтобы можно было вешать события
        for (let i = 0, coord; i < obj.path.length; i++) {
            coord = obj.path[i]

            if(i == 0) {
                // moveTo
                square += `M ${(coord[0] / multiplier)} ${(coord[1] / multiplier)}`
            } else {
                // lineTo
                square += ` L ${(coord[0] / multiplier)} ${(coord[1] / multiplier)}`
            }
        }

        if(isFill) {
            this.canvasContext.fillStyle = obj.fill
            this.canvasContext.fill(new Path2D(square))
        }
        // И пушим в массив для дальнейших манипуляции с ховером и кликом
        this.squareArr.push(new Path2D(square))
    }

    appendStatus(obj, multiplier, popupsBox) {
        const div = createStatusElement(obj)

        let top = this.getCenter(obj, multiplier)[1]
        let left = this.getCenter(obj, multiplier)[0]

        div.style.top = `${top}px`
        div.style.left = `${left}px`

        window.addEventListener('resize', (event) => {
            let scaleFactor = this.canvas.width / this.plan.offsetWidth;

            div.style.top = `${top / scaleFactor}px`
            div.style.left = `${left / scaleFactor}px`
        })

        popupsBox.appendChild(div)
    }

    getCenter(obj, multiplier) {
        let x = 0, y = 0
        let holderWidth = 70, holderHeight = 20
        let top = null, left = null, right = null, bottom = null

        for (let i = 0; i < obj.path.length; i++) {
            x = obj.path[i][0]
            y = obj.path[i][1]

            if (left === null || x < left) {
                left = x;
            }

            if (right === null || x > right) {
                right = x;
            }

            if (top === null || y < top) {
                top = y;
            }

            if (right === null || y > bottom) {
                bottom = y;
            }
        }

        let xResult = left + ((right - left) / 2) - (holderWidth / 2);
        let yResult = top + ((bottom - top) / 2) - (holderHeight / 2);

        return [xResult / multiplier, yResult / multiplier]
    }

    paintText(object) {
        // Текст внутри фигуры
        this.textCanvasContext.fillStyle = 'black';
        this.textCanvasContext.font = '12px Montserrat';
        this.textCanvasContext.fillText(object.text, ...object.textCoord)
    }

    checkObjectDefined(objects) {
        if(typeof objects !== 'undefined' && objects.length > 0) {
            return objects
        } else {
            console.error('Objects is undefined or empty', objects)
            return false;
        }
    }
    replaceFloorImage(item) {
        // Заменяем картинку
        const planImage = this.plan.querySelector('img')
        planImage.src = item.planning_image
    }

    canvasCoordsGet(key, obj) {
        for (const i in obj) {
            if (Object.hasOwnProperty.call(obj, i)) {
                if(key == i) {
                    return obj[key]
                }
            }
        }
    }
}

export function getHouseId(symbol = '') {
    const house = document.querySelector('[data-house-id]')
    if(house) {
        return `${symbol}facility_id=${house.dataset.houseId}`
    }
    return ''
}
// Возвращает id подьезда если возможно
function getEntrance(object, symbol = '', ravno = '=') {
    if(object) {
        return `${symbol}entrance${ravno}${object.entrance_id}`
    } else {
        return ''
    }
}

function selectFloorById(item) {
    document.getElementById(`floor${item.floor_id}`) ? document.getElementById(`floor${item.floor_id}`).checked = true : false
}
// Находим первый этаж у которого есть не проданные квартиры
function getFirstFloorIndex(objects) {
    for(let i = 0; i < objects.length; i++) {
        if(objects[i].status != 0) {
            return i
        } else {
            // Сделать вывод сообщения что все квартиры проданы
        }
    }
}

