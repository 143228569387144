import { get } from "./_panorama"
import Modal from "../../components/modal/modal";
import { currentRoom } from "./objectFilter/_current-room";

export function commercialItemLoader() {
    const bodys = document.querySelectorAll('[data-body-comercial]')

    if(bodys.length > 0) {
        bodys.forEach(el => setListener(el))
    }
}

function setListener(body ) {
    const links = body.querySelectorAll('[data-id]')

    if(links.length > 0) {
        body.addEventListener('click', () => listener(event, body,  links))
    }
}

function listener(event, body, links) {
    event.preventDefault()

    if([...links].includes(event.target)) {
        doLink(event.target, event.target.dataset.id, body.dataset.bodyComercial)
    }
}

function doLink(element, apartment_id, facility_id) {
    const link = `/sale/current_room?apartment_id=${apartment_id}&facility_id=${facility_id}`

    get('GET', link)
    .then((data) => {
        const response = JSON.parse(data.responseText)

        const container = new Modal(element, {
            onBeforeOpen: () => container.layout.classList.add('_big'),
            onAfterOpen: () => container.content.insertAdjacentHTML('beforeend', currentRoom(response.results))
        })
    })
}
