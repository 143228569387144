window.addEventListener('DOMContentLoaded',() => {
    const items = document.querySelectorAll('[data-toggle-content-item]');

    if (items.length > 0) {
        items.forEach((item, key) => toggleContent(item));
    }
});

function toggleContent(item) {
    const btn = item.querySelector('[data-toggle-content-btn]');

    if (btn) {
        btn.addEventListener('click', () => {
            item.classList.toggle('active');
        })
    }
}

