// Функция ymaps.ready() будет вызвана, когда
// загрузятся все компоненты API, а также когда будет готово DOM-дерево.
window.addEventListener('load', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }
})

let mapContacts;

function init() {
    const indexMap = document.getElementById('index-map')
    if(indexMap) {
        // Создание карты.
        let map = new ymaps.Map(indexMap, {
            // Координаты центра карты (широта, долгота).
            center: [58.603595, 49.668023], // getMapCenter(marks)
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 10,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        map.behaviors.disable('scrollZoom');
        // Добавление меток (массив обьектов "marks" передается в шаблоне)
        if(typeof marks != 'undefined') {
            createMarks(marks, map)
        }
    }

    const contactsMap = document.getElementById('contacts-map')
    if(contactsMap) {
        // Создание карты.
        mapContacts = new ymaps.Map(contactsMap, {
            // Координаты центра карты (широта, долгота). Только для начальной инициализации, затем заменятся метками и их координатами
            center: [58.603595, 49.668023],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 15,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        mapContacts.behaviors.disable('scrollZoom');
        // перебираем метки, добавляем нужную по индеку, остальные удаляем (массив обьектов "marks" передается в шаблоне)
        moveMap(0)
    }

    const objectMap = document.getElementById('object-map')
    if(objectMap) {
        // Создание карты.
        let map = new ymaps.Map(objectMap, {
            // Координаты центра карты (широта, долгота).
            center: getMapCenter(marks.house),
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 14,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        map.behaviors.disable('scrollZoom');
        // добавляем метку с домом
        if(typeof marks !== 'undefined') {
            if(marks.house) {
                createMarks(marks.house, map)
            }
            // Находим родительский блок карты и получаем все элементы с "data-name"
            const objects = map.container._parentElement.parentElement.querySelectorAll('[data-name]')

            objects.forEach((el, key) => {
                el.addEventListener('click', () => {
                    map.panTo(getMapCenter(marks[el.dataset.name]), {flying: 1})
                    addMark(el)
                })
            });
        }

        function addMark(el) {
            map.geoObjects.removeAll();
            createMarks(marks[el.dataset.name], map)
        }
    }
}

export function moveMap(index) {
    for(let i = 0; i < marks.length; i++) {
        if(marks[i].index == index) {
            mapContacts.geoObjects.removeAll();
            // плавное перемещение карты (аналог setCenter)
            mapContacts.panTo(marks[i].coord, {flying: 1})
            mapContacts.geoObjects.add(mark(marks[i]))
        }
    }
}


// создание меток из массива
function createMarks(arr, map) {
    if(arr.length > 0) {
        arr.forEach(el => {
            map.geoObjects.add(mark(el))
        });
    } else {
        throw new Error('Marks array empty');
    }
}
// объект создания метки
export function mark(el) {
    let link = null;
    if(el.url) {
        link = `<a href="${el.url}">Страница обьекта</a>`;
    }

    let content = [];
    if (el.text) content.push(el.text);
    if (el.name) content.push(el.name);

    return new ymaps.Placemark(el.coord, {
        hintContent: el.name || '',
        balloonContent: content ? content.join('<br>') : '',
        balloonContentFooter: link || ''
    }, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: el.image || 'static/images/base/placemark.png',
        // Размеры метки.
        iconImageSize: el.size || [30, 42],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-5, -38]
    })
}


// если нужно определить центр карты по меткам
function getMapCenter(arr) {
    if(typeof arr != 'undefined') {
        let x = 0, y = 0;
        if(arr.length > 0) {
            arr.forEach((el, key) => {
                x += el.coord[0]
                y += el.coord[1]
            })

            return [parseFloat(getCoord(x)), parseFloat(getCoord(y))];
        } else {
            throw new Error('Marks array empty');
        }

        function getCoord(coord) {
            return coord = (coord / arr.length).toFixed(7)
        }
    } else {
        // условный центр
        return [58.603595, 49.668023]
    }
}
