import { get } from "../_panorama"
import { getHouseId } from "./_canvas"
import { currentRoom } from "./_current-room"

export function createItem(item) {
    return `
        <div class="room__item item-room">
            <div class="item-room__image">
                <img src="${item.img}" alt="">
            </div>

            <div class="item-room__place">
                <svg>
                    <use xlink:href="/static/svg/sprite.svg#${item.icon}"></use>
                </svg>
                <p class="item-room__name">${item.name}</p>

                <p class="item-room__info">${item.info}</p>
                <p class="item-room__floor">${item.floor}</p>
            </div>

            <div class="item-room__description">
                <div class="item-room__data">
                    <p class="item-room__number">${item.room}</p>
                    <p class="item-room__what">комнаты</p>
                </div>
                <div class="item-room__data">
                    <p class="item-room__number">${item.square}</p>
                    <p class="item-room__what">кв.м</p>
                </div>
                <div class="item-room__data">
                    <p class="item-room__number">${parseFloat(item.price).toLocaleString('de')}</p>
                    <p class="item-room__what">рублей</p>
                </div>
            </div>

            <button data-href="${getApartmentLink(item.apartment_id)}" class="item-room__more">
                <span>Подробнее</span>
                <svg>
                    <use xlink:href="/static/svg/sprite.svg#return"></use>
                </svg>
            </button>
        </div>
    `
}

function getApartmentLink(id) {
    return `/sale/current_room?apartment_id=${id}${getHouseId('&')}`
}

export function getApartmentBody() {
    const container = document.querySelector('[data-sale-body].selected')
    const btns = document.querySelectorAll('[data-href]')

    btns.forEach(el => {
        el.addEventListener('click', () => {
            get('GET', el.dataset.href)
            .then((data) => {
                const response = JSON.parse(data.responseText)

                if(response.results) {
                    // Очищаем контейнер для нового содержимого
                    container.textContent = ''
                    // Вставляем новый контент
                    container.insertAdjacentHTML('beforeend', currentRoom(response.results))
                } else {
                    console.log('Xhr error');
                }
            })
        })
    })
}
