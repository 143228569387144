export function renderPlanForm(response, entrances) {
    return`
    <form data-filter="plan" class="room__filter-box" action="/sale/plan" method="GET">

        <div class="filter-entrance">
            <div class="filter-entrance__name">Подъезд</div>
            <div class="filter-entrance__box">
                ${getEntrance(entrances)}
            </div>
        </div>

        <div class="filter-floor">
            <div class="filter-floor__name">Этажи</div>
            <div class="filter-floor__box" data-floor>
                ${getFloors(response)}
            </div>
        </div>

    </form>`
}

function getEntrance(entrances) {
    let result = ''

    for (let i = 0; i < entrances.length; i++) {
        result += `
        <input class="filter-entrance__input" type="radio" name="entrance" id="entrance${entrances[i].entrance_id}" data-id="${entrances[i].entrance_id}">
        <label class="filter-entrance__item" for="entrance${entrances[i].entrance_id}">
            <p class="filter-entrance__value">${entrances[i].number}</p>
        </label>`
    }

    return result
}

export function getFloors(response) {
    let result = ''

    for (const floor of response.floors) {
        result += `
            <input class="filter-floor__input" ${checkFloorsDisabled(floor.status)} type="radio" name="floor" id="floor${floor.floor_id}" data-id="${floor.floor_id}">
            <label class="filter-floor__item" for="floor${floor.floor_id}">
                <p class="filter-floor__value">${getFloorNumber(floor.number)}</p>
                <div class="filter-floor__status">${getFloorsStatus(floor.status)}</div>
            </label>`
    }

    return result
}

function getFloorNumber(number) {
    if(number) {
        return number
    } else {
        return '0'
    }
}

function getFloorsStatus(status) {
    if(status) {
        return `${status} ${getEnd(status, 'кв')}`
    } else {
        return `Продано`
    }
}

function getEnd(status, word) {
    let condition = status % 10

    // 11,12,13,14 и остаток 5,6,7,8,9,0
    if (11 <= status && status <= 14 || condition == 0 || 5 <= condition && condition <= 9) {
        word += '-р'
    // 1 и остаток 1
    } else if (status == 1 || condition == 1) {
        word += '-ра'
    // 2,3,4 и остаток 2,3,4
    } else if (2 <= status && status <= 4 || 2 <= condition && condition <= 4) {
        word += '-ры'
    }

    return word
}

function checkFloorsDisabled(status) {
    if(status == 0) {
        return 'disabled'
    } else {
        return ''
    }
}
