export function currentRoom(item) {
    return `
    <div class="room__item current old">
        <div class="room__item__header">
            <svg class="svg-icon icon-logo print">
                <use xlink:href="/static/svg/sprite.svg#logo"></use>
            </svg>
            <p>Отдел продаж УКС АВИТЕК:
                <br>
                    <strong>тел. (8332) 739-230 www.uksavitek.ru
                <br>Новый офис на ул. Труда, 72а
                <br></strong></p>
        </div>
        <div class="current__left">
            <div class="current__image">
                <img src="${item.img || ''}" alt="${item.name || ''}" data-fancybox-trigger="object">
            </div>

            <div class="current__preview-box">
                ${getItems(item.thumbs) || ''}
            </div>

            <a href="/modal_call" class="current__link" data-modal>${item.btn}</a>
            <button class="current__print js-current-print">Распечатать</button>
        </div>

        <div class="current__right">
            <div class="current__place">
                <div class="current__svg" style="transform: rotate(${item.rot})">
                    <svg>
                        <use xlink:href="/static/svg/sprite.svg#compass"></use>
                    </svg>
                </div>

                <div class="current__body">
                    <p class="current__name">${item.name || ''}</p>

                    <p class="current__info">${item.info || ''}</p>
                    <p class="current__floor">${item.floor || ''}</p>
                </div>
            </div>

            <div class="current__description">
                <div class="current__data">
                    <p class="current__number">${item.room || 0}</p>
                    <p class="current__what">комнаты</p>
                </div>
                <div class="current__data">
                    <p class="current__number">${item.square || 0}</p>
                    <p class="current__what">кв.м</p>
                </div>
                <div class="current__data">
                    <p class="current__number">${parseFloat(item.price).toLocaleString('de') || 0}</p>
                    <p class="current__what">рублей</p>
                </div>
            </div>

            <div class="current__variant">
                ${getTradeIn(item.tradeIn) || ''}
                ${getPercent(item.percent) || ''}
            </div>

            <div class="current__text-block">
                <h3 class="current__h3">${item.title || ''}</h3>
                <p class="current__text">${item.text || ''}</p>

                <h3 class="current__h3">${item.advantages || ''}</h3>
                <ul class="current__list">
                    ${getList(item.list) || ''}
                </ul>
            </div>
        </div>
    </div>
    `
}


function getItems(items) {
    let string = ''

    for (const src of items) {
        string +=
        `<a href="${src}" data-fancybox="object">
            <img src="${src}" alt="">
        </a>`
    }
    return string
}

function getList(items) {
    let string = ''

    for (const text of items) {
        string +=`<li class="current__item">${text}</li>`
    }
    return string
}

function getPercent(item) {
    if(item) {
        return `<div class="current__variant-item">
                    <svg>
                        <use xlink:href="/static/svg/sprite.svg#percent"></use>
                    </svg>
                    Рассрочка
                </div>`
    }
}
function getTradeIn(item) {
    if(item) {
        return `<div class="current__variant-item">
                    <svg>
                        <use xlink:href="/static/svg/sprite.svg#trade-in"></use>
                    </svg>
                    trade-in
                </div>`
    }
}

function appednPrintFrame() {
    const printFrame = document.createElement("div");
    printFrame.className = "print-frame";

    // frame header
    const header = document.createElement("div");
    header.className = "print-frame__header";

    const logo = document.querySelector(".svg-icon.icon-logo");
    const contacts = document.createElement("div");
    contacts.className = "print-frame__contacts";
    contacts.innerHTML = `
        <p>
            Отдел продаж УКС АВИТЕК:<br/>
            <strong>
                тел. (8332) 739-230 www.uksavitek.ru <br/>
                Новый офис на ул. Труда, 72а <br/>
            </strong>
        </p>
        `;
    header.appendChild(logo);
    header.appendChild(contacts);


    // frame content
    const content = document.createElement("div");
    content.className = "print-frame__content";

    const currentLeft = document.querySelector(".room__item.current .current__left")

    const images = currentLeft.querySelectorAll("img");
    const imagesDiv = document.createElement("div");
    imagesDiv.className = "print-frame__images";
    images.forEach(img => {
        const imgClone = img.cloneNode(true);
        imagesDiv.appendChild(imgClone);
    })

    const roomContent = document.querySelector(".room__item.current").cloneNode(true);

    content.appendChild(imagesDiv);
    content.appendChild(roomContent);

    // put data in print frame
    printFrame.appendChild(header);
    printFrame.appendChild(content);

    const prevFrames = document.body.querySelectorAll(".print-frame");

    prevFrames.forEach(frame => frame.remove());

    document.body.appendChild(printFrame);
}

document.addEventListener("click", ({target}) => {
    if (typeof target.className.includes === 'undefined' || !target.className.includes("js-current-print")) return true;
    // appednPrintFrame();
    window.print();
});
