import {moveMap} from './default/_maps';
import {get} from './default/_panorama';
import {createItemNews} from './default/_item-news';
import {afterInput, updateItems} from './default/objectFilter/_range';
import {Tab} from './default/_TabClass';
import {commercialItemLoader} from './default/_commercialItemLoader';
import {initSimpleSlider, updateSimpleSlider} from './default/_swiper';

window.addEventListener('DOMContentLoaded', () => {
    const realizedTab = new Tab('[data-tab-btn]', '[data-tab-body]', {
        activeNumber: 0,
        useClass: 'selected',
        afterClick: () => updateRealized(realizedTab),
        afterInit: (inst) => {
            updateRealized(inst);
            select('[data-tab-select]', '[data-tab-select-container]');
        }
    });

    const saleTab = new Tab('[data-sale-btn]', '[data-sale-body]', {
        activeNumber: 0,
        useClass: 'selected',
        afterClick: () => afterInput(saleTab.getBodys()[saleTab.getActiveIndex()], false),
        afterInit: (inst) => {
            afterInput(saleTab.getBodys()[saleTab.getActiveIndex()], false);
            select('[data-sale-select]', '[data-sale-select-container]');
            showHide(inst);
        }
    });

    const contactsTab = new Tab('[data-contacts-btn]', '[data-contacts-body]', {
        activeNumber: 0,
        useClass: 'selected',
        afterClick: () => moveMap(contactsTab.getActiveIndex()),
        afterInit: () => {
            select('[data-contacts-select]', '[data-contacts-select-container]');
        }
    });

    new Tab('[data-btn-comercial]', '[data-body-comercial]', {
        activeNumber: false,
        useClass: 'selected',
        selfClose: true,
        afterInit: () => commercialItemLoader()
    });

    const simpleSlider = new Tab('[data-slider-simple-btn]', '[data-slider-simple-body]', {
        activeNumber: 0,
        useClass: 'selected',
        afterInit: (inst) => {
            select('[data-slider-simple-select]', '[data-slider-simple-select-container]');
            initSimpleSlider(inst.getBodys()[inst.getActiveIndex()]);
        },
        afterClick: () => {
            updateSimpleSlider(simpleSlider.getBodys()[simpleSlider.getActiveIndex()]);
        },
    });

});

// Скрывает/показывает фильтр на мобилке
function showHide(tab) {
    const btn = document.querySelector('[data-filter-btn]');

    if (btn) {
        btn.addEventListener('click', () => {
            tab.getBodys()[tab.getActiveIndex()].querySelector('[data-filter]').classList.toggle('show');
        });
    }
}

function select(selectSelector, containerSelectors) {
    const select = document.querySelectorAll(selectSelector);
    const container = document.querySelectorAll(containerSelectors);

    if (select.length > 0 && container.length == select.length) {
        select.forEach((el, key) => {
            init(el, container[key]);
        });
    }
}

function init(select, container) {
    const items = container.children;
    // установка видимого текста
    replaceText(select, items[0]);

    select.addEventListener('click', () => {
        action('toggle');
    });

    for (const item of items) {
        item.addEventListener('click', () => {
            replaceText(select, item);
            action('remove');
        });
    }

    function replaceText(elem, donor) {
        elem.textContent = donor.textContent;
    }

    // remove, add, toggle
    function action(action) {
        select.classList[action]('active');
        container.classList[action]('active');
    }
}

function updateRealized(selectedTab) {
    const selected = selectedTab.getBodys()[selectedTab.getActiveIndex()];
    const btn = selectedTab.getBtns()[selectedTab.getActiveIndex()];
    const container = selected.querySelector('[data-container]');

    if (selected.dataset.tabBody != 'loaded') {
        const loadMoreBtn = selected.querySelector('[data-tab-get]');
        loadMoreBtn ? loadMoreBtn.addEventListener('click', () => getRealized(selected, btn, container, false)) : false;
        // При первом вызове
        getRealized(selected, btn, container);
    } else {
        console.log('First content was loaded');
    }

}

function getRealized(selected, btn, container, replace) {
    get('GET', `/projects/ajax?${btn.dataset.tabBtn}`)
        .then((data) => {
            const response = JSON.parse(data.responseText);
            let items = [];

            if (response.results) {
                for (let index = 0; index < response.results.length; index++) {
                    items.push(createItemNews(response.results[index][0]));
                }
                updateItems(items, container, replace);
                selected.dataset.tabBody = 'loaded';
            }
        });
}
