export function createStatusElement(obj) {
    const div = document.createElement('div')
    const text = getStatusText(obj.status)

    div.classList.add('popup-item', 'hide', `popup-${obj.apartament_id}`);
    div.innerHTML =
    `<span class="popup-item__text status-${obj.status}">${text}</span>

        <div class="popup-item__body">
            <div class="popup-item__head">
                <span class="popup-item__text popup-item__text_inside status-${obj.status}">${text}</span>

                <div class="popup-item__appartment">
                    <span>Кв. №${obj.number}</span>
                    <span>${obj.rooms}-комн.</span>
                    <span>${obj.floor} этаж</span>
                </div>
            </div>

            <div class="popup-item__info">
                <div>
                    <span>S общая</span>
                    <span>${obj.area} м²</span>
                </div>
            </div>
        </div>`;

    return div;
}

function getStatusText(status) {
    if (status === 1) {
        return 'Свободно'
    } else if (status === 2) {
        return 'Резерв'
    } else if (status === 3) {
        return 'Продано'
    } else {
        return ''
    }
}

// function getKitchenArea(area) {
//     if(area) {
//         return `<div>
//                     <span>S кухни</span>
//                     <span>${area} м²</span>
//                 </div>`
//     }
//     return ''
// }
